import React, { useState, useEffect } from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";
import photosStyle from "./photos.module.css";
import santaPhotosStyle from "./socially-distant-santa-photos.module.css";
import Img from "gatsby-image";
import addToMailchimp from "gatsby-plugin-mailchimp";
import env from "../config/env";
import axios from "axios";
import Carousel from "react-multi-carousel";
import BabyPortraitCarousel from "../components/baby-portrait-carousel";
import HolidaysPortraitCarousel from "../components/holidays-portrait-carousel";
import ClassicPortraitCarousel from "../components/classic-portrait-carousel"
import styles from "./index.module.css";

let stripe;
if (typeof window !== "undefined") {
  stripe = window.Stripe(env.GATSBY_STRIPE_PUBLIC_KEY);
}

const PhotosPage = ({data}) => {

  const [finalPrice, setFinalPrice] = useState(0);
  const [currentSku, setCurrentSku] = useState('');
  const [showPortretTypeErrorMessage, setShowPortretTypeErrorMessage] = useState({ show: false, message: "" });
  const [showDeliveryTypeErrorMessage, setShowDeliveryTypeErrorMessage] = useState({ show: false, message: "" });
  const [showNameErrorMessage, setShowNameErrorMessage] = useState({ show: false, message: "" });
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState({ show: false, message: "" });
  const [showPhotoErrorMessage, setShowPhotoErrorMessage] = useState({ show: false, message: "" });
  const [backdropColSize, setBackdropColSize] = useState(4);
  const [themesColSize, setThemesColSize] = useState(12);
  const [selectedHolidayStyleImg, setSelectedHolidayStyleImg] = useState('valentine-day-style1');
  const [selectedClassicBackdropImg, setSelectedClassicBackdropImg] = useState('midnight1');
  const [babySex, selectBabySex] = useState('girl');

  useEffect(() => {
    changeHolidaySlide();
    changeClassicSlide();
  }, [babySex]);

  const [formState, setFormValues] = useState({
    portraitType: "Holiday",
    classicBackdropStyle: "Midnight",
    nurseryStyle: "",
    holidayStyle: "Valentine Day",
    babyName: "",
    deliveryType: "",
    frameColor: "",
    name: "",
    email: "",
    discountCode: "",
    photoB64: "",
    photoName: "",
    noOfCopies: 1,
    path: "/photos",
  });

  const defaultStyles = {
    classicBackdropStyle: "",
    nurseryStyle: "",
    holidayStyle: "",
  };

  const defaultPrices = {
    digitalDelivery: 20,
    digitalDeliveryAndPrint: 40,
    digitalDeliveryAndPrintAndFrame: 70,
  };

  const holidayStyles = [{
      id: 'valentine-day-style',
      title: 'Valentine Day',
      images: [
        'valentine-day-style1',
        'valentine-day-style2',
      ],
    }, {
      id: 'easter-style',
      title: 'Easter',
      images: [
        'easter-style1',
        'easter-style2',
      ],
    }, {
      id: 'mothers-day-style',
      title: 'Mothers Day',
      images: [
        'mothers-day-style1',
        'mothers-day-style2',
      ],
    }, {
      id: 'fathers-day-style',
      title: 'Fathers Day',
      images: [
        'fathers-day-style1',
        'fathers-day-style2',
      ],
    }, {
      id: 'red-winter-themed-style',
      title: 'Winter - Red Theme',
      images: [
        'red-winter-themed-style1',
        'red-winter-themed-style2',
      ],
    }, {
      id: 'blue-winter-themed-style',
      title: 'Winter - Blue Theme',
      images: [
        'blue-winter-themed-style1',
        'blue-winter-themed-style2',
      ],
    }
  ];

  const changeHolidaySlide = (holidayStyle) => {
    if (!holidayStyle) {
      holidayStyle = holidayStyles.find(item => item.title === formState.holidayStyle);
    }
    if (holidayStyle) {
      setSelectedHolidayStyleImg(holidayStyle.images[babySex === 'girl' ? 0 : 1]);
    }
  }

  const classicBackdrops = [
    {
      id: 'Midnight',
      title: 'Midnight',
      images: [
        'midnight1',
        'midnight2',
      ],
      cssClass: photosStyle.midnightColor,
    },
    {
      id: 'Coconut',
      title: 'Coconut',
      images: [
        'coconut1',
        'coconut2',
      ],
      cssClass: photosStyle.coconutColor,
    },
    {
      id: 'Rose',
      title: 'Rose',
      images: [
        'rose1',
        'rose2',
      ],
      cssClass: photosStyle.roseColor,
    },
    {
      id: 'Lime',
      title: 'Lime',
      images: [
        'lime1',
        'lime2',
      ],
      cssClass: photosStyle.limeColor,
    },
    {
      id: 'Blue',
      title: 'Blue',
      images: [
        'blue1',
        'blue2',
      ],
      cssClass: photosStyle.blueClassicColor,
    }
  ];

  const changeClassicSlide = (classicStyle) => {
    if (!classicStyle) {
      classicStyle = classicBackdrops.find(item => item.title === formState.classicBackdropStyle);
    }
    if (classicStyle) {
      setSelectedClassicBackdropImg(classicStyle.images[babySex === 'girl' ? 1 : 0]);
    }
  }

  const skus = {
    digitalDelivery: 'sku_IVPU8oXMXVCbKb',
    digitalDeliveryAndPrint: 'sku_IVPZFOxF9PLDLL',
    digitalDeliveryAndPrint10Off: 'sku_IVQcwsTWjfMLIv',
    digitalDeliveryAndPrint20Off: 'sku_IVQZg6ybfEUk5y',
    digitalDeliveryAndPrintAndFrame: 'sku_IVPaPxsiplNVdu',
    digitalDeliveryAndPrintAndFrame10Off: 'sku_IVQe05XbmHnUOS',
    digitalDeliveryAndPrintAndFrame20Off: 'sku_IVQfvHiOa69U7g',
  };

  // TEST SKUS. Uncomment the following code when you want to use Stripe test data
  // const skus = {
  //   digitalDelivery: 'sku_IVdUu8r2GIUEc5',
  //   digitalDeliveryAndPrint: 'sku_IVdVLbJ1YioDrt',
  //   digitalDeliveryAndPrint10Off: 'sku_IVdX7lDlt9nFPv',
  //   digitalDeliveryAndPrint20Off: 'sku_IVdWXZaFP3p96t',
  //   digitalDeliveryAndPrintAndFrame: 'sku_IVdV4VtCdTlT3j',
  //   digitalDeliveryAndPrintAndFrame10Off: 'sku_IVdXArxLcKBTtn',
  //   digitalDeliveryAndPrintAndFrame20Off: 'sku_IVdY6fetxsw0Vh',
  // };

  const updateCurrentSku = (deliveryType, noOfCopies) => {
    noOfCopies = parseInt(noOfCopies);
    let sku = '';
    if (deliveryType === 'Digital Delivery Only') {
      sku = skus.digitalDelivery;
    } else if (deliveryType === 'Digital Delivery and Print and Ship a 5x7 and 8x10') {
      if (noOfCopies === 1) {
        sku = skus.digitalDeliveryAndPrint;
      } else if (noOfCopies === 2) {
        sku = skus.digitalDeliveryAndPrint10Off;
      } else {
        sku = skus.digitalDeliveryAndPrint20Off;
      }
    } else if (deliveryType === 'Digital Delivery and Print, Frame and Ship Finished Art') {
      if (noOfCopies === 1) {
        sku = skus.digitalDeliveryAndPrintAndFrame;
      } else if (noOfCopies === 2) {
        sku = skus.digitalDeliveryAndPrintAndFrame10Off;
      } else {
        sku = skus.digitalDeliveryAndPrintAndFrame20Off;
      }
    }
    setCurrentSku(sku);
  };

  const updateFinalPrice = (data) => {
    const deliveryType = data['deliveryType'] || formState.deliveryType;
    const noOfCopies = data['noOfCopies'] || formState.noOfCopies;
    let price = 0;
    let discountPercent = 0;
    let shouldCheckForDiscount = false;

    if (deliveryType === 'Digital Delivery Only') {
      price = defaultPrices.digitalDelivery;
    } else if (deliveryType === 'Digital Delivery and Print and Ship a 5x7 and 8x10') {
      price = defaultPrices.digitalDeliveryAndPrint;
      shouldCheckForDiscount = true;
    } else if (deliveryType === 'Digital Delivery and Print, Frame and Ship Finished Art') {
      price = defaultPrices.digitalDeliveryAndPrintAndFrame;
      shouldCheckForDiscount = true;
    }

    if (shouldCheckForDiscount) {
      discountPercent = noOfCopies === 2 ? 10 : (noOfCopies >= 3 ? 20 : 0);
    }

    price *= noOfCopies;
    const finalPrice = price - price * discountPercent / 100;
    setFinalPrice(finalPrice);

    updateCurrentSku(deliveryType, noOfCopies);
  };

  const chooseHoliday = () => {
    setFormValues({...formState, ...defaultStyles, portraitType: "Holiday", holidayStyle: holidayStyles[0].title});
    changeHolidaySlide(holidayStyles[0]);
  };

  const chooseClassic = () => {
    setFormValues({...formState, ...defaultStyles, portraitType: "Classic", classicBackdropStyle: classicBackdrops[0].title});
    setBackdropColSize(4);
    changeClassicSlide(classicBackdrops[0]);
  };

  const chooseNursery = () => {
    setFormValues({...formState, ...defaultStyles, portraitType: "Nursery"});
    setBackdropColSize(3);
  };

  const chooseDigitalDeliveryOnly = (e) => {
    setFormValues({...formState, noOfCopies: 1, deliveryType: e.target.value});
    updateFinalPrice({deliveryType: e.target.value, noOfCopies: 1});
  };

  const handleFile = (formChangeEvent) => {
    const file = formChangeEvent.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      setFormValues({
        ...formState,
        photoName: file.name,
        photoB64: btoa(readerEvent.target.result),
      });
    };
    reader.readAsBinaryString(file);
  };

  const handleRegisterForPhotosFormChange = e => {
    if (e.target.name === "photo") {
      handleFile(e);
    } else {
      let value = e.target.value;
      if (e.target.name === "noOfCopies") {
        value = parseInt(e.target.value);
      }
      setFormValues({
        ...formState,
        [e.target.name]: value,
      });
    }

    if (e.target.name === "deliveryType" || e.target.name === "noOfCopies") {
      updateFinalPrice({[e.target.name]: e.target.value});
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!formState.portraitType) {
      isValid = false;
      setShowPortretTypeErrorMessage({
        show: true,
        message: "Please choose a portrait style!"
      })
    }
    if (!formState.deliveryType) {
      isValid = false;
      setShowDeliveryTypeErrorMessage({
        show: true,
        message: "Please choose how you want it delivered!"
      })
    }
    if (!formState.name) {
      isValid = false;
      setShowNameErrorMessage({
        show: true,
        message: "Please enter your name!"
      })
    }
    if (!formState.email) {
      isValid = false;
      setShowEmailErrorMessage({
        show: true,
        message: "Please enter your email!"
      })
    }
    if (!formState.photoB64) {
      isValid = false;
      setShowPhotoErrorMessage({
        show: true,
        message: "Please upload a photo of your child!"
      })
    }

    return isValid
  };

  const saveToMailchimp = async () => {
    try {
      await addToMailchimp(formState.email, {
          PORTRAIT: formState.portraitType,
          CLASSIC: formState.classicBackdropStyle,
          NURSERY: formState.nurseryStyle,
          HOLIDAY: formState.holidayStyle,
          BABYNAME: formState.babyName,
          DLVRTYPE: formState.deliveryType,
          FRAMECOLOR: formState.frameColor,
          NAME: formState.name,
        }, env.submitPhotosUrl
      );
    } catch (err) {
      console.error(err);
    }
    return true;
  };

  const savePhotoToS3 = async () => {
    return axios({
      method: 'post',
      url: env.uploadPhotoUrl,
      data: {
        email: formState.email,
        base64Photo: formState.photoB64,
      },
      headers: {
        'Access-Control-Allow-Origin': window.location.origin,
      },
    });
  };

  const redirectToStripe = async () => {
    const shipping = formState.deliveryType === "Digital Delivery Only" ? undefined : { allowedCountries: ["US"] };
    return await stripe.redirectToCheckout({
      items: [{
        sku: currentSku,
        quantity: formState.noOfCopies,
      }],
      customerEmail: formState.email,
      successUrl: env.STRIPE_PHOTOS_PORTRAIT_SUCCESS_URL,
      cancelUrl: window ? window.location.protocol + "//" + window.location.host + formState.path : "",
      billingAddressCollection: "required",
      shippingAddressCollection: shipping,
    });
  };

  const validateAndSaveData = async () => {
    if (validateForm()) {
      await saveToMailchimp();
      await savePhotoToS3();
      await redirectToStripe();
    }
  };

  return (
    <Layout>
      <Container>
        <div>
          <div className="title">
            <h1 className={globalStyle.entryTitle}>Order Your Baby or Toddlers Professional Portrait Today!</h1>
          </div>
        </div>

        <Row>
          <Col xs={12} md={12} sm={12}>
            <video width="100%" height="auto" autoPlay loop muted playsInline>
              <source src={data.portraitVideo.publicURL} type="video/mp4"/>
            </video>
          </Col>
        </Row>

        <Row className={[photosStyle.holidayCarouselContent, "carouselContent"].join(" ")}>
          <Col>
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              autoPlay={true}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024
                  },
                  items: 1,
                  partialVisibilityGutter: 40
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0
                  },
                  items: 1,
                  partialVisibilityGutter: 30
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464
                  },
                  items: 2,
                  partialVisibilityGutter: 30
                }
              }}
              showDots={true}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              { data.babyPortraitCarousel.edges.map(edge => (
                <div key={edge.node.name}>
                  <BabyPortraitCarousel
                    photo={edge.node.childImageSharp.fluid}
                  >
                  </BabyPortraitCarousel>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>

        <Row>
          <Img className={photosStyle.proccess} fluid={data.holidayFullView.childImageSharp.fluid}/>
          <Img fluid={data.classicFullView.childImageSharp.fluid}/>
          <Img fluid={data.nurseryFullView.childImageSharp.fluid}/>
        </Row>

        <Row>
          <Col>
            <h1 className={[photosStyle.sectionTitle, photosStyle.largeTitle].join(" ")}>Choose Your Theme!</h1>
          </Col>
        </Row>

        <Row className={[photosStyle.nextRowIcon, photosStyle.largeNextRowIcon].join(" ")}>
          <Col>
            <i className="fa fa-angle-double-down"></i>
          </Col>
        </Row>

        <Row className={santaPhotosStyle.rowStyle}>
          <Col md={themesColSize}>
            <Form
              name="getYourPortrait"
              onSubmit={(e) => {e.preventDefault();}}
            >
              {/*<Form.Row>*/}
              {/*  <h5>Select your Portrait<span className={globalStyle.required}></span></h5>*/}
              {/*</Form.Row>*/}
              <Form.Row className={photosStyle.rowStyle}>
                <Col className={photosStyle.imagesColumn} xs={6} md={6} sm={6} onClick={chooseHoliday}>
                  <Form.Check className={photosStyle.styleRadiobox}>
                    <Form.Check.Label htmlFor="holiday" >
                      <Form.Check.Input type='radio' id="holiday" name="portraitType"
                                        checked={formState.portraitType === "Holiday"}
                                        onChange={chooseHoliday}/>
                      HOLIDAY <span className="d-none d-sm-inline">PORTRAIT</span></Form.Check.Label>
                  </Form.Check>
                  {/*<Img fluid={data.holidayPortrait.childImageSharp.fluid}/>*/}
                  <Row className="">
                    <Col>
                        { data.holidaysCarousel.edges.map(edge => {
                          return (
                            <div key={edge.node.name} className={selectedHolidayStyleImg === edge.node.name ? '' : 'd-none'}>
                              <HolidaysPortraitCarousel
                                photo={edge.node.childImageSharp.fluid}
                              >
                              </HolidaysPortraitCarousel>
                            </div>
                          )
                        })}
                    </Col>
                  </Row>

                  <div className={photosStyle.portraitOverlay}>
                    <div className={photosStyle.potraitOverlayContent}>
                    {formState.portraitType === "Holiday" && <span>Selected</span>}
                    {formState.portraitType !== "Holiday" && <span>Choose this style</span>}
                    </div>
                  </div>
                </Col>

                <Col className={photosStyle.imagesColumn} xs={6} md={6} sm={6} onClick={chooseClassic}>
                  <Form.Check className={photosStyle.styleRadiobox} >
                    <Form.Check.Label htmlFor="classic">
                      <Form.Check.Input type='radio' id="classic" name="portraitType"
                                        checked={formState.portraitType === "Classic"}
                                        onChange={chooseClassic}/>
                      CLASSIC <span className="d-none d-sm-inline">PORTRAIT</span></Form.Check.Label>
                  </Form.Check>
                  {/*<Img fluid={data.classicPortrait.childImageSharp.fluid}/>*/}

                  <Row className={[photosStyle.classicCarouselContent, ""].join(" ")}>
                    <Col>

                      { data.classicCarousel.edges.map(edge => (
                        <div key={edge.node.name} className={selectedClassicBackdropImg === edge.node.name ? '' : 'd-none'}>
                          <ClassicPortraitCarousel
                            photo={edge.node.childImageSharp.fluid}
                          >
                          </ClassicPortraitCarousel>
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <div className={photosStyle.babyNameOverlay}>
                    <div className={photosStyle.babyNameOverlayContent}>
                      <span>{formState.babyName}</span>
                    </div>
                  </div>

                  <div className={photosStyle.portraitOverlay}>
                    <div className={photosStyle.potraitOverlayContent}>
                      {formState.portraitType === "Classic" && <span>Selected</span>}
                      {formState.portraitType !== "Classic" && <span>Choose this style</span>}
                    </div>
                  </div>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col xs={12} md={12} sm={12}>
                  {showPortretTypeErrorMessage.show && (
                    <Alert
                      variant="danger"
                      onClose={() => setShowPortretTypeErrorMessage({ show: false })}
                      dismissible
                    >
                      {showPortretTypeErrorMessage.message}
                    </Alert>
                  )}
                </Col>
              </Form.Row>

              {formState.portraitType === "Classic" &&
              <Row>
                <h3 className={photosStyle.sectionTitle}>Type Your Baby Name</h3>
              </Row>
              }

              {formState.portraitType === "Classic" &&
              <Form.Row className={[photosStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                <Col xs={10} sm={8} md={6}>
                  <Form.Group as={Col} controlId="babyName" name="babyName"
                              className={[santaPhotosStyle.registerFormGroup, santaPhotosStyle.formLabel]}>
                    <Form.Control
                      className={photosStyle.formInput}
                      size="sm"
                      value={formState.babyName}
                      type="text"
                      name="babyName"
                      placeholder="Type baby name"
                      onChange={handleRegisterForPhotosFormChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={2} sm={2} md={2}>
                    <span className={photosStyle.babySex}>
                        <i className={['fa', 'fa-venus', photosStyle.babySexGirl].join(' ')}
                           onClick={() => selectBabySex('girl')}></i>
                        <i className={['fa', 'fa-mars', photosStyle.babySexBoy].join(' ')}
                           onClick={() => selectBabySex('boy')}></i>
                    </span>
                </Col>
              </Form.Row>
              }

              { (formState.portraitType === "Classic" || formState.portraitType === "Nursery") &&
              <Row>
                <h3 className={photosStyle.sectionTitle}>Choose Your Backdrop Color</h3>
              </Row>
              }
              { formState.portraitType === "Classic" &&
              <div className={[photosStyle.nextRowIcon, photosStyle.mediumNextRowIcon].join(" ")}>
                <i className="fa fa-angle-double-down"></i>
              </div>
              }

              { (formState.portraitType === "Classic" || formState.portraitType === "Nursery") &&
              <Form.Row className={[photosStyle.rowStyle, photosStyle.backdropChoicesRow, "justify-content-md-center"].join(" ")}>
                {classicBackdrops.map(classicBackdrop => (
                <Col key={classicBackdrop.id}>
                  <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox]}>
                    <Form.Check.Input type='radio' id={classicBackdrop.id} name="classicBackdropStyle" value={classicBackdrop.id}
                                      checked={formState.classicBackdropStyle === classicBackdrop.id}
                                      onChange={(e) => {handleRegisterForPhotosFormChange(e); changeClassicSlide(classicBackdrop)}}/>
                    <Form.Check.Label htmlFor={classicBackdrop.id}>{classicBackdrop.title}</Form.Check.Label>
                    <div className={[photosStyle.colorSample, classicBackdrop.cssClass].join(" ")}></div>
                  </Form.Check>
                </Col>
                ))}
              </Form.Row>
              }

              { formState.portraitType === "Nursery" &&
                <Row>
                  <h3 className={photosStyle.sectionTitle}>Choose Style</h3>
                </Row>
              }
              { formState.portraitType === "Nursery" &&
                <div className={[photosStyle.nextRowIcon, photosStyle.mediumNextRowIcon].join(" ")}>
                  <i className="fa fa-angle-double-down"></i>
                </div>
              }

              { formState.portraitType === "Nursery" &&
                <Form.Row className={[photosStyle.rowStyle, photosStyle.backdropChoicesRow].join(" ")}>
                  <Col xs={4} md={4} sm={4}>
                    <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox, photosStyle.nurseryStyleRadiobox]}>
                      <Form.Check.Input type='radio' id="bottles" name="nurseryStyle" value="Bottles"
                                        checked={formState.nurseryStyle === "Bottles"}
                                        onChange={handleRegisterForPhotosFormChange}/>
                      <Form.Check.Label htmlFor="bottles">Bottles</Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col xs={4} md={4} sm={4}>
                    <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox, photosStyle.nurseryStyleRadiobox]}>
                      <Form.Check.Input type='radio' id="pacifier" name="nurseryStyle" value="Pacifier"
                                        checked={formState.nurseryStyle === "Pacifier"}
                                        onChange={handleRegisterForPhotosFormChange}/>
                      <Form.Check.Label htmlFor="pacifier">Pacifier</Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col xs={4} md={4} sm={4}>
                    <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox, photosStyle.nurseryStyleRadiobox]}>
                      <Form.Check.Input type='radio' id="toy-backdrop" name="nurseryStyle" value="ToyBackdrop"
                                        checked={formState.nurseryStyle === "ToyBackdrop"}
                                        onChange={handleRegisterForPhotosFormChange}/>
                      <Form.Check.Label htmlFor="toy-backdrop">Toy</Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Form.Row>
              }

              { formState.portraitType === "Holiday" &&
              <Row>
                <h3 className={photosStyle.sectionTitle}>Choose Style</h3>
              </Row>
              }
              { formState.portraitType === "Holiday" &&
              <div className={[photosStyle.nextRowIcon, photosStyle.mediumNextRowIcon].join(" ")}>
                <i className="fa fa-angle-double-down"></i>
              </div>
              }

              {formState.portraitType === "Holiday" &&
                <Form.Row className={[photosStyle.rowStyle, photosStyle.backdropChoicesRow].join(" ")}>
                  {
                    holidayStyles.map(holidayStyle => (
                      <Col key={holidayStyle.id}>
                        <Form.Check
                          className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox, photosStyle.holidayStyleRadiobox]}>
                          <Form.Check.Input type='radio'
                                            id={holidayStyle.id}
                                            name="holidayStyle"
                                            value={holidayStyle.title}
                                            checked={formState.holidayStyle === holidayStyle.title}
                                            onChange={(e) => {handleRegisterForPhotosFormChange(e); changeHolidaySlide(holidayStyle)}}/>
                          <Form.Check.Label htmlFor={holidayStyle.id}>{holidayStyle.title}</Form.Check.Label>
                        </Form.Check>
                      </Col>))
                  }
                </Form.Row>
              }

              <Row>
                <h3 className={photosStyle.sectionTitle}>Choose Your Delivery</h3>
              </Row>

              <div className={[photosStyle.nextRowIcon, photosStyle.mediumNextRowIcon].join(" ")}>
                <i className="fa fa-angle-double-down"></i>
              </div>

              <Form.Row className={photosStyle.rowStyle}>
                <Col xs={12} sm={12} md={4} className={photosStyle.digitalDeliveryContent}>
                  <div className={photosStyle.deliveryOptionContent}>
                    <div className={[photosStyle.deliveryIcon, "text-center"].join(" ")}>
                      <i className={[photosStyle.iconSize, "fa fa-envelope"].join(" ")} aria-hidden="true"></i>
                    </div>

                    <div className={photosStyle.priceContainer}>
                      <span className={photosStyle.price}>${defaultPrices.digitalDelivery}</span>
                    </div>

                    <div className={photosStyle.digitalDeliveryOption}>
                      Digital Delivery
                    </div>

                    <Form.Check
                      className={ formState.deliveryType === "Digital Delivery Only" ? photosStyle.selectedDeliveryButton : photosStyle.selectDeliveryButton }>
                      <Form.Check.Input type='radio' id="digital-delivery-only"
                                        name="deliveryType"
                                        value="Digital Delivery Only"
                                        checked={formState.deliveryType === "Digital Delivery Only"}
                                        onChange={chooseDigitalDeliveryOnly}/>
                      { formState.deliveryType === "Digital Delivery Only" &&
                      <Form.Check.Label htmlFor="digital-delivery-only">Selected</Form.Check.Label>
                      }
                      { formState.deliveryType !== "Digital Delivery Only" &&
                      <Form.Check.Label htmlFor="digital-delivery-only">Select</Form.Check.Label>
                      }
                    </Form.Check>
                  </div>
                </Col>

                <Col xs={12} sm={12} md={4} className={photosStyle.digitalDeliveryContent}>
                  <div className={photosStyle.deliveryOptionContent}>
                    <div className={[photosStyle.deliveryIcon, "text-center"].join(" ")}>
                      <i className={[photosStyle.iconSize, "fa fa-envelope"].join(" ")} aria-hidden="true"></i>
                      <i className={[photosStyle.plusIcon, "fa fa-plus"].join(" ")}></i>
                      <i className={[photosStyle.iconSize, "fa fa-file-image"].join(" ")} aria-hidden="true"></i>
                    </div>

                    <div className={photosStyle.priceContainer}>
                      <span className={photosStyle.price}>${defaultPrices.digitalDeliveryAndPrint}</span>
                    </div>

                    <div className={photosStyle.digitalDeliveryOption}>
                      <p>Digital Delivery</p>
                      <p>Printed 5x7 and Printed 8x10</p>
                    </div>

                    <Form.Check className={ formState.deliveryType === "Digital Delivery and Print and Ship a 5x7 and 8x10" ? photosStyle.selectedDeliveryButton : photosStyle.selectDeliveryButton }>
                      <Form.Check.Input type='radio' id="digital-delivery-and-print-and-ship"
                                        name="deliveryType"
                                        value="Digital Delivery and Print and Ship a 5x7 and 8x10"
                                        checked={formState.deliveryType === "Digital Delivery and Print and Ship a 5x7 and 8x10"}
                                        onClick={() => setShowDeliveryTypeErrorMessage({ show: false })}
                                        onChange={handleRegisterForPhotosFormChange}/>
                      {formState.deliveryType === "Digital Delivery and Print and Ship a 5x7 and 8x10" &&
                        <Form.Check.Label htmlFor="digital-delivery-and-print-and-ship">
                          Selected
                        </Form.Check.Label>
                      }
                      {formState.deliveryType !== "Digital Delivery and Print and Ship a 5x7 and 8x10" &&
                      <Form.Check.Label htmlFor="digital-delivery-and-print-and-ship">
                        Select
                      </Form.Check.Label>
                      }
                    </Form.Check>
                  </div>
                </Col>

                <Col xs={12} sm={12} md={4} className={photosStyle.digitalDeliveryContent}>
                  <div className={photosStyle.deliveryOptionContent}>
                    <div className={[photosStyle.deliveryIcon, "text-center"].join(" ")}>
                      <i className={[photosStyle.iconSize, "fa fa-envelope"].join(" ")} aria-hidden="true"></i>
                      <i className={[photosStyle.plusIcon, "fa fa-plus"].join(" ")}></i>
                      <i className={[photosStyle.iconSize, "fa fa-file-image"].join(" ")} aria-hidden="true"></i>
                      <i className={[photosStyle.plusIcon, "fa fa-plus"].join(" ")}></i>
                      <div className={photosStyle.frameIcon}></div>
                    </div>

                    <div className={photosStyle.priceContainer}>
                      <span className={photosStyle.price}>${defaultPrices.digitalDeliveryAndPrintAndFrame}</span>
                    </div>

                    <div className={photosStyle.digitalDeliveryOption}>
                      <p>Digital Delivery</p>
                      <p>Printed 5x7 and Printed 8x10</p>
                      <p>Matted and framed 8x10</p>
                    </div>

                    <Form.Check className={ formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" ? photosStyle.selectedDeliveryButton : photosStyle.selectDeliveryButton }>
                      <Form.Check.Input type='radio' id="digital-delivery-and-print-frame-and-ship-art"
                                        name="deliveryType"
                                        value="Digital Delivery and Print, Frame and Ship Finished Art"
                                        checked={formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art"}
                                        onClick={() => setShowDeliveryTypeErrorMessage({show: false})}
                                        onChange={handleRegisterForPhotosFormChange}/>
                      <Form.Check.Label htmlFor="digital-delivery-and-print-frame-and-ship-art">
                        {formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" &&
                        <span>Selected</span>
                        }
                        {formState.deliveryType !== "Digital Delivery and Print, Frame and Ship Finished Art" &&
                        <span>Select</span>
                        }
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </Col>

              </Form.Row>

              <Form.Row>
                <Col xs={12} md={12} sm={12}>
                  {showDeliveryTypeErrorMessage.show && (
                    <Alert
                      variant="danger"
                      onClose={() => setShowDeliveryTypeErrorMessage({ show: false })}
                      dismissible
                    >
                      {showDeliveryTypeErrorMessage.message}
                    </Alert>
              )}
                </Col>
              </Form.Row>

              {
                (formState.deliveryType === "Digital Delivery and Print and Ship a 5x7 and 8x10"
                  // ||
                  // formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art"
                ) &&
                <Row>
                  <Col>
                    <p>
                      <span className={globalStyle.required}></span>
                      <span className={globalStyle.required}></span>
                      <span className={globalStyle.required}></span>
                      &nbsp;
                      <span className={photosStyle.deliveryInfo}>Discount:</span>&nbsp;&nbsp;


                      <span className={photosStyle.aboutProduct}>
                       Buy 2 get 10% off, Buy 3 or more get 20% off
                      </span>
                    </p>
                  </Col>
                </Row>
              }

              {formState.deliveryType &&
              <Row>
                <Col>
                  {formState.deliveryType !== "Digital Delivery and Print, Frame and Ship Finished Art" &&
                  <p>

                  <span className={globalStyle.required}></span>
                  <span className={globalStyle.required}></span>
                  <span className={globalStyle.required}></span>
                  &nbsp;


                  <span className={photosStyle.deliveryInfo}>Delivery: &nbsp;&nbsp;</span>

                  {
                    formState.deliveryType === "Digital Delivery Only" &&
                    <span className={photosStyle.aboutProduct}>
                      Emailed to you in 24 to 48 Hours or Money Back.</span>
                  }

                  {
                    formState.deliveryType === "Digital Delivery and Print and Ship a 5x7 and 8x10" &&
                    <span className={photosStyle.aboutProduct}>
                      Emailed to you in 24 to 48 Hours or Money Back. Prints Arrive 5-7 Days from Digital Delivery Date.
                    </span>
                  }
                  </p>
                  }
                </Col>
              </Row>
              }

              { formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" &&
              <Row>
                <h3 className={photosStyle.sectionTitle}>Choose Frame Color</h3>
              </Row>
              }

              { formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" &&
              <div className={[photosStyle.nextRowIcon, photosStyle.mediumNextRowIcon].join(" ")}>
                <i className="fa fa-angle-double-down"></i>
              </div>
              }

              { formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" &&
                <Form.Row className={photosStyle.rowStyle}>
                  <Col xs={12} md={3} sm={3}>
                    <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox]}>
                      <Form.Check.Input type='radio' id="black" name="frameColor" value="Black"
                                        onChange={handleRegisterForPhotosFormChange}/>
                      <Form.Check.Label htmlFor="black">Black</Form.Check.Label>
                      <div className={[photosStyle.colorSample, photosStyle.midnightColor].join(" ")}></div>
                    </Form.Check>
                  </Col>
                  <Col xs={12} md={3} sm={3}>
                    <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox]}>
                      <Form.Check.Input type='radio' id="white" name="frameColor" value="White"
                                        onChange={handleRegisterForPhotosFormChange}/>
                      <Form.Check.Label htmlFor="white">White</Form.Check.Label>
                      <div className={[photosStyle.colorSample, photosStyle.whiteColor].join(" ")}></div>
                    </Form.Check>
                  </Col>
                  <Col xs={12} md={3} sm={3}>
                    <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox]}>
                      <Form.Check.Input type='radio' id="grey" name="frameColor" value="Grey"
                                        onChange={handleRegisterForPhotosFormChange}/>
                      <Form.Check.Label htmlFor="grey">Grey</Form.Check.Label>
                      <div className={[photosStyle.colorSample, photosStyle.greyColor].join(" ")}></div>
                    </Form.Check>
                  </Col>
                  <Col xs={12} md={3} sm={3}>
                    <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox]}>
                      <Form.Check.Input type='radio' id="blue" name="frameColor" value="Blue"
                                        onChange={handleRegisterForPhotosFormChange}/>
                      <Form.Check.Label htmlFor="blue">Blue</Form.Check.Label>
                      <div className={[photosStyle.colorSample, photosStyle.blueColor].join(" ")}></div>
                    </Form.Check>
                  </Col>
                </Form.Row>
              }

              {
                (formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art") &&
                <Row>
                  <Col>
                    <p>
                      <span className={globalStyle.required}></span>
                      <span className={globalStyle.required}></span>
                      <span className={globalStyle.required}></span>
                      &nbsp;
                      <span className={photosStyle.deliveryInfo}>Discount:</span>&nbsp;&nbsp;
                      <span className={photosStyle.aboutProduct}>
                       Buy 2 get 10% off, Buy 3 or more get 20% off
                      </span>
                    </p>
                    <p>
                      <span className={globalStyle.required}></span>
                      <span className={globalStyle.required}></span>
                      <span className={globalStyle.required}></span>
                      &nbsp;
                      <span className={photosStyle.deliveryInfo}>Delivery:</span>&nbsp;&nbsp;
                      <span className={photosStyle.aboutProduct}>
                      Emailed to you in 24 to 48 Hours or Money Back. Framed Portrait Received 5-7 Days from Digital
                      Delivery Date.</span>
                    </p>
                  </Col>
                </Row>
              }

              <Row>
                <h3 className={photosStyle.sectionTitle}>Upload Your Photo</h3>
              </Row>

              <div className={[photosStyle.nextRowIcon, photosStyle.mediumNextRowIcon].join(" ")}>
                <i className="fa fa-angle-double-down"></i>
              </div>

              <Form.Row className={[photosStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                <Col xs={12} md={8} sm={10}>
                  <Form.Group>
                    <Form.File
                      id="custom-file-translate-scss"
                      label={formState.photoName || "Select & Upload Photo"}
                      lang="en"
                      custom
                      accept="image/jpeg,image/jpg"
                      name="photo"
                      onClick={() => setShowPhotoErrorMessage({ show: false })}
                      onChange={handleRegisterForPhotosFormChange}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              {showPhotoErrorMessage.show &&
              <Form.Row className="justify-content-md-center">
                <Col xs={12} md={8} sm={10}>
                  <Alert
                    variant="danger"
                    onClose={() => setShowPhotoErrorMessage({ show: false })}
                    dismissible
                  >
                    {showPhotoErrorMessage.message}
                  </Alert>
                </Col>
              </Form.Row>
              }

              <Row className="justify-content-md-center">
                <Col xs={12} md={8} sm={10}>
                  {/*<h5 className={photosStyle.messageStyle}>*/}
                  {/*  All we need is any photo of your Baby or Toddler that shows their face, neck and upper chest and we*/}
                  {/*  will transform it into a fine professional portrait for you! Our goal is to deliver something you and*/}
                  {/*  your family absolutely love.</h5>*/}
                  <h5 className={photosStyle.messageStyle}>
                    Image Guidelines:
                  </h5>
                  <ul className={photosStyle.messageStyle}>
                    <li>Use a smartphone or high-quality camera.</li>
                    <li>Take a photo or choose an existing photo taken during daylight hours or in a bright space.</li>
                    <li>Ensure the whole face and neck is visible and there are no shadows.</li>
                    <li>Upload your favorite look and let us get to work for you!</li>
                    Not sure whether your image will work? <a className={globalStyle.redirectLink} href={"mailto:aimeecruz@momsonmaternity.com"}>Email us</a> and we will let you know!
                  </ul>
                </Col>
              </Row>

              <Form.Row className={[photosStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                <Col xs={12} sm={10} md={8}>
                  <Form.Group as={Col} controlId="formName" className={[santaPhotosStyle.registerFormGroup, santaPhotosStyle.formLabel]}>
                    <Form.Control
                      size="sm"
                      value={formState.name}
                      name="name"
                      type="text"
                      placeholder="Type your name"
                      onClick={() => setShowNameErrorMessage({ show: false })}
                      onChange={handleRegisterForPhotosFormChange}
                      className={photosStyle.formInput}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              {showNameErrorMessage.show &&
              <Form.Row className="justify-content-md-center">
                <Col xs={12} md={8} sm={10}>
                  <Alert
                    variant="danger"
                    onClose={() => setShowNameErrorMessage({show: false})}
                    dismissible
                  >
                    {showNameErrorMessage.message}
                  </Alert>
                </Col>
              </Form.Row>
              }

              <Form.Row className={[photosStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                <Col xs={12} md={8} sm={10}>
                  <Form.Group as={Col} controlId="formName" className={[santaPhotosStyle.registerFormGroup, santaPhotosStyle.formLabel]}>
                    <Form.Control
                      size="sm"
                      value={formState.email}
                      name="email"
                      type="email"
                      placeholder="Type your email address"
                      onClick={() => setShowEmailErrorMessage({ show: false })}
                      onChange={handleRegisterForPhotosFormChange}
                      className={photosStyle.formInput}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              {showEmailErrorMessage.show &&
              <Form.Row className="justify-content-md-center">
                <Col xs={12} md={8} sm={10}>

                    <Alert
                      variant="danger"
                      onClose={() => setShowEmailErrorMessage({ show: false })}
                      dismissible
                    >
                      {showEmailErrorMessage.message}
                    </Alert>
                </Col>
              </Form.Row>
              }

              <Row className="justify-content-md-center">
                <Col className="text-center">
                  <Button
                    type="button"
                    className={[
                      globalStyle.btnPrimary,
                      photosStyle.goldButton,
                      photosStyle.buyNowButton
                    ]}
                    onClick={validateAndSaveData}
                  >
                    BUY NOW {finalPrice ? ' $' + finalPrice : ''}
                  </Button>
                </Col>
              </Row>

            </Form>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    portraitPhotos: allFile(filter: {relativePath: {regex: "/portrait-photos/portraits\/.*/"}}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    classicPortrait: file(relativePath: { eq: "portrait-photos/portraits-white/classic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    classicFullView: file(relativePath: { eq: "portrait-photos/frames/Lucas-regular-full-view.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    holidayPortrait: file(relativePath: { eq: "portrait-photos/portraits-white/holiday.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    holidayFullView: file(relativePath: { eq: "portrait-photos/frames/Olivia-holiday-full-view.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nurseryPortrait: file(relativePath: { eq: "portrait-photos/portraits-white/nursery.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nurseryFullView: file(relativePath: { eq: "portrait-photos/frames/Olivia-nursery-full-view.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    portraitVideo: file(
      relativePath: {eq: "portrait-photos/videos/portrait-video.mp4"}
    ) {
        publicURL
        name
    }
    babyPortraitCarousel: allFile(filter: {relativePath: {regex: "/portrait-photos/frames-process\/.*/"}}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    holidaysCarousel: allFile(filter: {relativePath: {regex: "/portrait-photos/holidays\/.*/"}}, sort: {fields: publicURL}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    classicCarousel: allFile(filter: {relativePath: {regex: "/portrait-photos/classic\/.*/"}}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    skus: allStripeSku {
      edges {
        node {
          id
          price
          attributes {
            size
          }
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export default PhotosPage
